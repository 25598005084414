import { Action, PayloadAction, createSlice } from "@reduxjs/toolkit";

import { MeQuery } from "../../route/HexRoutes.generated";
import { RootState } from "../store";

export type MeSliceState = NonNullable<MeQuery["me"]> | null;

const meSlice = createSlice({
  name: "me",
  initialState: null as MeSliceState,
  reducers: {
    setMe: (_, action: PayloadAction<MeSliceState>) => action.payload,
    clearMe: (_, __: Action) => null as MeSliceState,
  },
});

export const meReducer = meSlice.reducer;
export const { clearMe, setMe } = meSlice.actions;
export const selectMe = (state: RootState): MeSliceState => state.me;
